const theme = {
  font: {
    primary: `'Open Sans', sans-serif`
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  color: {
    primary: '#E7C8F2',
    dark_primary: '#8D6E98',
    dark_grey: '#707070',
    grey: '#808080',
    black: '#000000',
    white: '#FFFFFF'
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
